<div class="wb-faq">
  <h2>Masz pytanie związane z programem?</h2>
  <p>Znajdź odpowiedź na najczęściej zadawane pytania</p>
  <div class="wb-faq__separator mb-lg-5 mb-4"></div>

  <div #question1 (click)="change(question1)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Ile etykiet muszę przesłać? / Ile faktur mogę dołączyć ?</p>
    <p>
      Zgodnie z regulaminem promocji wienerberger Korzyści Murowane dopuszczalne są maksymalnie trzy faktury, wystawione
      w okresie trwania promocji. Zakup dokonany musi być w jednym i tym samym punkcie sprzedaży, niezbędna jest
      wcześniejsza aktywacja promocji
    </p>
  </div>

  <div #question2 (click)="change(question2)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Czy mogę mieć paragon? / Czy akceptowany jest paragon ?</p>
    <p>
      W ramach promocji akceptowane są wyłącznie imienne faktury końcowe. Faktury zaliczkowe, faktury proforma oraz
      paragony fiskalne nie są akceptowane.
    </p>
  </div>

  <div #question3 (click)="change(question3)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Czy mogę przesłać zgłoszenie na maila?</p>
    <p>
      Dokumenty przesłać można wyłącznie za pośrednictwem swojego konta utworzonego na stronie www.korzyscimurowane.pl
      przed aktywacją promocji. W celu uzupełnienie brakujących dokumentów należy przesłać je na adres
      kontakt&#64;korzyscimurowane.pl
    </p>
  </div>

  <div #question4 (click)="change(question4)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Czy mogę dostać ofertę cenową?</p>
    <p>
      wienerberger oraz Semmelrock prowadzą sprzedaż wyłącznie w swoich punktach sprzedaży. Po ofertę cenową należy
      zgłosić się do tych punktów. Lista dystrybutorów znajduje się na stronie www.korzyscimurowane.pl
    </p>
  </div>

  <div #question5 (click)="change(question5)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Czy wyliczenie materiałów musi być wgrane na stronie?</p>
    <p>
      Aby otrzymać wyliczenie ilości produktów, należy przesłać zgłoszenie za pomocą formularza dostępnego na swoim
      koncie utworzonym na stronie www.korzyscimurowane.pl. Zgłoszenia przesyłane inną drogą, w tym mailowo, nie będą
      realizowane.
    </p>
  </div>

  <div #question6 (click)="change(question6)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Czy mogę zrobić dwa zgłoszenia skoro wydam na materiały 30 tys. zł?</p>
    <p>
      W ramach promocji wienerberger Korzyści Murowane udzielany jest jeden rabat promocyjny w ramach danej kategorii
      produktowej tzn. jeden na pustaki ceramiczne Porotherm, jeden na dachówki ceramiczne Koramic oraz jeden na
      produkty brukowe Semmelrock.
    </p>
  </div>

  <div #question7 (click)="change(question7)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Czy dostanę kod promocyjny?</p>
    <p>
      W Promocji wienerberger Korzyści Murowane nie ma kodów promocyjnych. Aby wziąć udział w promocji należy założyć
      konto na stronie www.korzyscimurowane.pl, a następnie spełnić warunki opisane w regulaminie promocji. Regulamin
      promocji dostępny jest na stronie www.korzyscimurowane.pl
    </p>
  </div>

  <div #question8 (click)="change(question8)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Czy kwota będzie odjęta od faktury do dystrybutora?</p>
    <p>
      Nie, zwrot dokonywany jest wyłącznie na rachunek bankowy podany podczas rejestracji konta, po pozytywnej
      weryfikacji wniosku, opisanej szczegółowo w regulaminie promocji.
    </p>
  </div>

  <div #question9 (click)="change(question9)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Kupiłem materiał przez wykonawcę. Data z której faktury obowiązuje mnie w promocji?</p>
    <p>
      W przypadku zakupu materiałów wraz z usługą montażową w procesie weryfikacji decyduje data faktury końcowej
      wystawionej przez wykonawcę
    </p>
  </div>

  <div #question10 (click)="change(question10)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Czy etykieta produktu jest konieczna?</p>
    <p>
      Zgodnie z regulaminem etykieta produktu jest dokumentem bezwzględnie wymaganym w procesie weryfikacji. Etykieta
      znajduje się na foli opakowaniowej produktu.
    </p>
  </div>

  <div #question11 (click)="change(question11)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Ile etykiet muszę przesłać?</p>
    <p>Wymagane jest jedno zdjęcie etykiety produktu w danej kategorii produktowej.</p>
  </div>

  <div #question12 (click)="change(question12)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Jak długo trwa promocja?</p>
    <p>
      Aktualnie promocja nie ma daty końcowej. <br />
      Okres Dostępności Rabatu na każdą z grup produktów (Porotherm, Koramic, Semmelrock) to 3 miesiące od dnia
      aktywacji Okresu Dostępności Rabatu przez uczestnika, z możliwością jednokrotnego przedłużenia o jeden miesiąc.
      Przedłużenia można dokonać za pośrednictwem infolinii lub skrzynki mailowej
      <a href="mailto:kontakt@korzyscimurowane.pl">kontakt&#64;korzyscimurowane.pl</a>.
    </p>
  </div>

  <div #question13 (click)="change(question13)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Kiedy powinienem założyć konto uczestnika ?</p>
    <p>
      Konto uczestnika należy założyć przed planowanym dokonaniem zakupu. Również przed dokonaniem zakupu należy
      aktywować Okres Dostępności Rabatu dla danej grupy produktowej. Aktywacja Okresu Dostępności Rabatu uprawnia do
      pełnego udziału w promocji.
    </p>
  </div>

  <div #question14 (click)="change(question14)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Kiedy powinienem aktywować Okres Dostępności Rabatu?</p>
    <p>
      Okres Dostępności Rabatu należy aktywować przed dokonaniem zakupu. Termin ważności Okresu Dostępności Rabatu to 3
      miesiące od dnia aktywacji, z możliwością jednokrotnego przedłużenia o jeden miesiąc. Przedłużenia można dokonać
      za pośrednictwem infolinii lub skrzynki mailowej kontakt&#64;korzyscimurowane.pl
    </p>
  </div>

  <div #question15 (click)="change(question15)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Czy w promocji mogą brać udział produkty z zagranicznej produkcji?</p>
    <p>
      W promocji wienerberger Korzyści Murowane udział biorą wszystkie produkty dostępne w ofercie wienerberger - lista
      dostępna jest na stronie www.wienerberger.pl, oraz wybrane produkty brukowe Semmelrock . Szczegółowa lista
      produktów Semmelrock objętych promocją dostępna jest na stronie www.korzyscimurowane.pl
    </p>
  </div>

  <div #question16 (click)="change(question16)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Czy mogę założyć i prowadzić konto razem ze współmałżonkiem, skoro kupujemy produkty do wspólnej inwestycji?</p>
    <p>
      Konto uczestnika jest unikalne dla osoby, która rejestruje swój udział w promocji. Ze względu na ochronę danych
      osobowych, nie ma możliwości aby konto uczestnika było przypisane do dwóch osób. Wszystkie dokumenty wymagane w
      regulaminie powinny być wystawione na uczestnika, który zarejestrował się na stronie www.korzyscimurowane.pl
    </p>
  </div>

  <div #question17 (click)="change(question17)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Na kogo powinna być wystawiona faktura VAT?</p>
    <p>
      Faktura VAT powinna być wystawiona na Uczestnika, który zarejestrował się na stronie www.korzyscimurowane.pl.
      Faktura wystawiona na inną osobę, niezależnie od stopnia pokrewieństwa/powinowactwa z Uczestnikiem, uniemożliwia
      weryfikację wniosku promocyjnego.
    </p>
  </div>

  <div #question18 (click)="change(question18)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>
      Czy inna osoba (partner/członek rodziny) może prowadzić moje konto (kontaktować się z infolinią, przesyłać
      dokumenty)?
    </p>
    <p>
      Ze względu na ochronę danych osobowych, jedyną osobą uprawnioną do decydowania oraz uzyskiwania informacji na
      temat Konta Uczestnika, jest sam Uczestnik. Nie ma możliwości rejestracji konta w imieniu osoby trzeciej.
    </p>
  </div>

  <div #question19 (click)="change(question19)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>Co jeśli zweryfikowałam/em konto w latach 2020 - 2023?</p>
    <p>
      Należy aktywować Okres Dostępności Rabatu przed dokonaniem zakupu oraz wgrać wniosek promocyjny na Koncie
      Uczestnika. Wniosek zostanie zweryfikowany w zależności od daty data wgrania dokumentów do systemu (dokumenty
      dotyczące wniosków wgranych do 26.06.2024 r. będą rozliczane zgodnie z zasadami zawartymi w regulaminie
      obowiązującym do 26.06.2024 r., dokumenty dotyczące wniosków wgranych od 27.06.2024 zostaną zweryfikowane według
      zasad zawartych w regulaminie obowiązującym od 27.06.2024 r.).
    </p>
  </div>

  <div #question20 (click)="change(question20)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>
      Co jeśli aktywowałem okres dostępności rabatu w okresie od 14 lutego 2024 do 27 czerwca 2024, ale nie skorzystałem
      jeszcze z promocji?
    </p>
    <p>
      Wniosek zostanie zweryfikowany w zależności od daty wgrania dokumentów do systemu. Dokumenty dotyczące wniosków
      wgranych do 27.06.2024 r. zostaną zweryfikowane zgodnie z zasadami zawartymi w regulaminie obowiązującym do
      26.06.2024 r. Dokumenty dotyczące wniosków wgranych od 27.06.2024 r. zostaną zweryfikowane według zasad zawartych
      regulaminie obowiązującym od 27.06.2024 r.
    </p>
  </div>

  <div #question21 (click)="change(question21)" aria-expanded="false" class="wb-faq__question">
    <div class="arrow">></div>
    <p>
      W jakiej wysokości otrzymam rabat promocyjny jeśli wgrałem wniosek do 26.06.2024, a zostanie on zweryfikowany w po
      tej dacie?
    </p>
    <p>
      Wniosek zostanie zweryfikowany w zależności od daty wgrania dokumentów do systemu. Dokumenty dotyczące wniosków
      wgranych do 26.06.2024 r. zostaną zweryfikowane zgodnie z zasadami zawartymi w regulaminie obowiązującym do
      26.06.2024 r. Dokumenty dotyczące wniosków wgranych od 27.06.2024 r. zostaną zweryfikowane według zasad zawartych
      regulaminie obowiązującym od 27.06.2024 r.
    </p>
  </div>
</div>
